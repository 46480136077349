import { lazy } from "react";
import UploadPage from "./UploadPage";
import UserManagementPage from "./UserManagement";
import ListData from "./DataManagement/ListData";
import DetailPage from "./DataManagement/DetailData";
import ExportPage from "./ExportPage";
import WebServicesPage from "./WebServices"

const HomePage = lazy(() => import("./Homepage"))
const Login = lazy(() => import("./Login"))

let routes = [
    {
        path: "/login",
        exact: true,
        public: true,
        element: <Login />
    },
    {
        path: "/",
        exact: true,
        public: true,
        element: <HomePage />
    },
    {
        path: "/upload-pdf",
        exact: true,
        public: true,
        element: <UploadPage />
    },
    {
        path: "/user-management",
        exact: true,
        public: true,
        element: <UserManagementPage />
    },
    {
        path: "/list-data",
        exact: true,
        public: true,
        element: <ListData />
    },
    {
        path: "/detail-data/:referenceCode",
        exact: true,
        public: true,
        element: <DetailPage />
    },
    {
        path: "/export-data",
        exact: true,
        public: true,
        element: <ExportPage />
    },
    {
        path: "/web-service",
        exact: true,
        public: true,
        element: <WebServicesPage />
    },
]

export default routes