export const tableColumns = {
  DocumentsColumns: [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
      editable: true,
    },
    {
      title: "CSV File Name",
      dataIndex: "csvFileName",
      key: "csvFileName",
      editable: true,
    },
    {
      title: "Reference Code",
      dataIndex: "referenceCode",
      key: "referenceCode",
      editable: true,
    },
    {
      title: "H Label",
      dataIndex: "hlabel",
      key: "hlabel",
      editable: true,
    },
    {
      title: "Delete Flag",
      dataIndex: "deletedFlag",
      key: "deletedFlag",
      editable: true,
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
  ],
  DocumentPropertiesColumns: [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
    },
    {
      title: "Key",
      dataIndex: "keyDocument",
      key: "keyDocument",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Property Order",
      dataIndex: "propertyOrder",
      key: "propertyOrder",
    },
    {
      title: "Int Value",
      dataIndex: "intValue",
      key: "intValue",
    },
    {
      title: "Dec Value",
      dataIndex: "decValue",
      key: "decValue",
    },
    {
      title: "Dt Value",
      dataIndex: "dtValue",
      key: "dtValue",
    },
    {
      title: "Timestamp Value",
      dataIndex: "tmsValue",
      key: "tmsValue",
    },
    {
      title: "OCR Result",
      dataIndex: "ocrResult",
      key: "ocrResult",
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
  ],
  DocumentRowsColumns: [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
    },
    {
      title: "Row Order",
      dataIndex: "rowOrder",
      key: "rowOrder",
    },
    {
      title: "Row Note",
      dataIndex: "rowNote",
      key: "rowNote",
    },
    {
      title: "Deleted Flag",
      dataIndex: "deletedFlag",
      key: "deletedFlag",
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
  ],
  RowPropertiesColumns: [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
    },
    {
      title: "Document Row Id",
      dataIndex: "documentRowId",
      key: "documentRowId",
    },
    {
      title: "Key",
      dataIndex: "keyDocument",
      key: "keyDocument",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Property Order",
      dataIndex: "propertyOrder",
      key: "propertyOrder",
    },
    {
      title: "Int Value",
      dataIndex: "intValue",
      key: "intValue",
    },
    {
      title: "Dec Value",
      dataIndex: "decValue",
      key: "decValue",
    },
    {
      title: "Dt Value",
      dataIndex: "dtValue",
      key: "dtValue",
    },
    {
      title: "Timestamp Value",
      dataIndex: "tmsValue",
      key: "tmsValue",
    },
    {
      title: "OCR Result",
      dataIndex: "ocrResult",
      key: "ocrResult",
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
  ],
};
