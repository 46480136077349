// Button.jsx

import React from "react";
import { Button, ConfigProvider } from "antd";

const AntdButton = ({
  type,
  size,
  onClick,
  label,
  autoInsertSpaceInButton,
  disabled = false,
  ...props
}) => {
  if (autoInsertSpaceInButton === false) {
    return (
      <ConfigProvider autoInsertSpaceInButton={false}>
        <Button disabled={disabled} type={type} size={size} onClick={onClick} {...props}>
          {label}
        </Button>
      </ConfigProvider>
    );
  } else
    return (
      <Button disabled={disabled} type={type} size={size} onClick={onClick} {...props}>
        {label}
      </Button>
    );
};

export default AntdButton;
