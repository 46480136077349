import { Radio } from "antd";
import React from "react";

const AntdRadio = ({ options, onChange, value, ...props }) => (
  <Radio.Group onChange={onChange} value={value} {...props}>
    {options?.map((option, index) => (
      <Radio key={index} value={option.value}>
        {option.label}
      </Radio>
    ))}
  </Radio.Group>
);
export default AntdRadio;
