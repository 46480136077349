import { Form, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { processServices } from "services/ProcessService";
import { documentServices } from "services/DocumentService";
import ListDataPage from "stories/Pages/ListDataPage/ListDataPage";

const { Title } = Typography;

const DEFAULT_PAGINATION = {
  pageNo: 1,
  pageSize: 10,
  total: 0,
};

const ListDataContainer = () => {
  const { getListProcess, getListProcessStatus } = processServices;
  const { getListDocumentType } = documentServices;
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listDocsType, setListDocsType] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [searchCondition, setSearchCondition] = useState(undefined);

  useEffect(() => {
    getListProcessAPI(pagination);
    getListProcessStatusAPI();
    getListDocumentTypeAPI();
  }, []);

  const getListProcessStatusAPI = async () => {
    let results = await getListProcessStatus();
    results.map((item) => {
      item.value = item.id;
      item.label = item.name;
      return item;
    });
    setListStatus(results);
  };

  const getListDocumentTypeAPI = async () => {
    let results = await getListDocumentType();
    results.map((item) => {
      item.value = item.type;
      item.label = item.type;
      return item;
    });
    setListDocsType(results);
  };

  useEffect(() => {
    getListProcessAPI(pagination);
  }, [searchCondition]);

  const getListProcessAPI = async (currentPagination) => {
    const params = {
      ...currentPagination,
      ...(searchCondition && {
        toDate: searchCondition.toDate
          ? dayjs(searchCondition.toDate).format("YYYY-MM-DD")
          : undefined,
        fromDate: searchCondition.fromDate
          ? dayjs(searchCondition.fromDate).format("YYYY-MM-DD")
          : undefined,
        status: searchCondition.status,
        transactionCode: searchCondition.transactionCode,
      }),
    };
    getListProcess(params)
      .then((res) => {
        if (res?.content) {
          let content = res.content.map((item) => {
            item.key = item.idTranProcess;
            item.filenamePDF = item.pdfFileName;
            item.documentType = item?.type?.type;
            item.transactionCode = item.transactionCode;
            item.statusName = item.statusName;
            item.timeUpload = item.uplTms;
            return item;
          });
          setData(content);
          setPagination({ ...params, total: res?.totalElements });
        } else {
          console.log("NO DATA");
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      });
  };

  const handleChangePagination = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await getListProcessAPI(params);
    setPagination(params);
  };

  const onFinish = (values) => {
    setSearchCondition(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="mt-5 mx-10">
      <div className="container">
        <Title level={2}>データ一覧</Title>
      </div>
      <ListDataPage
        listStatus={listStatus}
        listDocsType={listDocsType}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        data={data}
        onChange={handleChangePagination}
        pagination={{
          size: "default",
          total: pagination.total,
          pageSize: pagination.pageSize,
          current: pagination.pageNo,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 50, 100],
          locale: { items_per_page: "/ページ" },
        }}
      />
    </div>
  );
};

export default ListDataContainer;
