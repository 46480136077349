import { api } from "config/api.js";

class DocumentServices {
  getDetailDocument = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/documents?${queryString}`);
  };
  getDocumentProperties = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/document-properties?${queryString}`);
  };
  getDocumentRows = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/document-rows?${queryString}`);
  };
  getRowsProperties = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/row-properties?${queryString}`);
  };
  getListDocumentType = async () => {
    return api.get(`/api/documents/list/type-document`);
  };
  createNewDetailDocument = async (params) => {
    params.isSaveLog = true;
    return api.post(`/api/documents/create`, { ...params });
  };
  updateDetailDocument = async (params) => {
    params.isSaveLog = true;
    return api.patch(`/api/documents/update/${params.id}`, { ...params });
  };
  deleteDetailDocument = async (params) => {
    const { id } = params;
    return api.delete(`/api/documents/delete/${id}`, { isSaveLog: true });
  };
  createNewDocumentProperties = async (params) => {
    params.isSaveLog = true;
    return api.post(`/api/document-properties/create`, { ...params });
  };
  updateDocumentProperties = async (params) => {
    params.isSaveLog = true;
    return api.patch(`/api/document-properties/update/${params.id}`, {
      ...params,
    });
  };
  deleteDocumentProperties = async (params) => {
    const { id } = params;
    return api.delete(`/api/document-properties/delete/${id}`, {
      isSaveLog: true,
    });
  };
  createNewDocumentRows = async (params) => {
    params.isSaveLog = true;
    return api.post(`/api/document-rows/create`, { ...params });
  };
  updateDocumentRows = async (params) => {
    params.isSaveLog = true;
    return api.patch(`/api/document-rows/update/${params.id}`, { ...params });
  };
  deleteDocumentRows = async (params) => {
    const { id } = params;
    return api.delete(`/api/document-rows/delete/${id}`, { isSaveLog: true });
  };
  createNewRowProperties = async (params) => {
    params.isSaveLog = true;
    return api.post(`/api/row-properties/create`, { ...params });
  };
  updateRowProperties = async (params) => {
    params.isSaveLog = true;
    return api.patch(`/api/row-properties/update/${params.id}`, { ...params });
  };
  deleteRowProperties = async (params) => {
    const { id } = params;
    return api.delete(`/api/row-properties/delete/${id}`, { isSaveLog: true });
  };
}

export const documentServices = new DocumentServices();
