import AntdButton from "stories/Button/AntdButton";

const ExportDataPage = () => {
  return (
    <div className="container mx-auto">
      <div className="mt-20 flex flex-row justify-center">
        <div
          className="border border-black p-6 bg-white w-2/3"
          style={{ height: "35rem" }}
        >
          <h1 className="text-2xl font-bold mb-4">
            Centered Container with Border
          </h1>
        </div>
      </div>
      <div className="mt-10 mb-5 flex space-x-4 justify-end">
        <AntdButton type="primary" label={"戻る"} className="w-40" />
        <AntdButton type="primary" label={"出力"} className="w-40" autoInsertSpaceInButton={false} />
      </div>
    </div>
  );
};

export default ExportDataPage;
