import { api } from "../config/api";

class WebService {
  getListFolder = async (params) => {
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === "") {
        delete params[key];
      }
    });
    const queryString = new URLSearchParams(params).toString();
    return api.get(`api/transaction/list?${queryString}`);
  };

  deleteFolder = async (params) => {
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );
    const queryString = new URLSearchParams(params).toString();
    return api.delete(`/api/transaction/delete?${queryString}`, {
      isSaveLog: true,
    });
  };

  getListSearchFolder = async (params) => {
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/transaction/list?${queryString}`, { isSaveLog: true });
  };

  createFolder = async (params) => {
    params.isSaveLog = true;
    return api.post("/api/transaction/create", { ...params });
  };

  updateFolder = async (id, params) => {
    params.isSaveLog = true;
    return api.patch(`/api/transaction/update/${id}`, { ...params });
  };

  sortFolder = async (params) => {
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/transaction/list?${queryString}`, { isSaveLog: true });
  };
}

export const webService = new WebService();
