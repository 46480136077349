import { api } from "config/api.js";

class UserServices {
    getListUser = async (params) => {
        const { pageNo, pageSize } = params;
        return api.get(`/api/users/list-users?pageNo=${pageNo}&pageSize=${pageSize}`);
    };
    getUserByID = async (params) => {
        const { id } = params;
        return api.get(`/api/users/${id}`);
    };
    createUser = async (params) => {
        params.isSaveLog = true
        return api.post("/api/users/create-user", { ...params });
    };
    updateUser = async (params) => {
        params.isSaveLog = true
        return api.put(`/api/users/update-user/${params.id}`, { ...params });
    };
    resetPassword = async (params) => {
        const { id } = params;
        params.isSaveLog = true
        return api.put(`/api/users/reset-password/${id}`, { ...params });
    };
    changePassword = async (params) => {
        params.isSaveLog = true
        return api.put("/api/auth/change-password", { ...params });
    };
    deleteUser = async (params) => {
        Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
        const queryString = new URLSearchParams(params).toString();
        return api.delete(`/api/users/delete-user?${queryString}`, { isSaveLog: true });
    };
}

export const userServices = new UserServices();