import ExportDataPage from "stories/Pages/ExportDataPage";

const ExportDataContainer = () => {
  return (
    <>
      <ExportDataPage />
    </>
  );
};

export default ExportDataContainer;
