import {Button, Form, Input, message, Modal, notification, Radio} from "antd";
import AntdButton from "stories/Button/AntdButton";
import AntdRadio from "../../stories/Radio/AntdRadio";
import MESSAGES from "config/constant/messages";
import {webService} from "../../services/WebService";
import {useEffect, useState} from "react";
import AntdInput from "../../stories/Input/AntdInput";

const CreateFolderModal = ({
                               isModalOpen,
                               onCloseModal,
                               editingRecord,
                               setEditingRecord,
                               getListFolderAPI,
                               pagination,
                               options
                           }) => {
    const [form] = Form.useForm();
    const isEditing = Object.keys(editingRecord).length > 0;
    // const [defaultRadioValue, setDefaultRadioValue] = useState(1); // Giá trị mặc định cho ô radio button
    const [radioValue, setRadioValue] = useState(2);
    const {createFolder, updateFolder} = webService;

    useEffect(() => {
        if (editingRecord && Object.keys(editingRecord).length > 0) {
            form.setFieldsValue({
                transactionType: editingRecord.transactionType === "輸出" ? 1 : 2,
                transactionCode: editingRecord.transactionCode,
            });
        } else {
            form.setFieldsValue({
                transactionType: 2,
                transactionCode: "",
            });
        }
    }, [editingRecord]);

    const onFinish = async (values) => {
        if (Object.keys(editingRecord).length > 0) {
            updateFolder(editingRecord.key, {...values})
                .then(() => {
                    message.success(MESSAGES.MSG_I_014(editingRecord.transactionCode));
                    getListFolderAPI(pagination);
                    handleCancel();
                }).catch((err) => {
                message.error(
                    err?.message
                )
            });
        } else {
            const { transactionCode } = values;
            createFolder({...values})
                .then(() => {
                    message.success(MESSAGES.MSG_I_013(transactionCode));
                    getListFolderAPI(pagination);
                    handleCancel();
                })
                .catch((err) => {
                    message.error(MESSAGES.MSG_E_018)
                })
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleCancel = () => {
        form.resetFields();
        setEditingRecord({});
        setRadioValue(2)
        onCloseModal();
    };

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value); // Convert the value to an integer
    };


    return (
        <>
        <Modal
            title={isEditing ? "Webフォルダー編集" : "Webフォルダー追加"} // Sửa tiêu đề tùy thuộc vào việc chỉnh sửa hay tạo mới
            open={isModalOpen}
            onCancel={handleCancel}
            destroyOnClose={true}
            centered={true}
            footer={[
                <Button
                    className="button-white"
                    key="cancel"
                    onClick={handleCancel}
                    type="primary"
                >
                    キャンセル
                </Button>,
                <AntdButton
                    label="保存"
                    className="button-white"
                    key="link"
                    htmlType="submit"
                    type={"primary"}
                    // loading={loading}
                    onClick={() => form.submit()}
                    autoInsertSpaceInButton={false}
                />,
            ]}
        >
            <div className="create-folder-container">
                <div className="create-folder-form-container">
                    <Form
                        name="basic"
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Form.Item
                            label="種別"
                            name="transactionType"
                            initialValue={2}
                            // rules={[
                            //     {
                            //         required: !isEditing,
                            //         message: MESSAGES.MSG_E_003("種別"),
                            //     },
                            // ]}
                        >
                            <AntdRadio
                                options={options}
                                onChange={handleRadioChange}
                                disabled={isEditing}
                            />
                        </Form.Item>
                        <Form.Item
                            label="取引コード"
                            name="transactionCode"
                            rules={[
                                {
                                    required: true,
                                    message: MESSAGES.MSG_E_003("取引コード"),
                                },
                                {
                                    // maxLength: 255,
                                    // message: MESSAGES.MSG_E_003("取引コード"),
                                },
                            ]}
                        >
                            <AntdInput maxLength={255} allowClear={true}/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
        </>
    );
};

export default CreateFolderModal;
