import { Tabs, Typography, Form, ConfigProvider } from "antd";
import MESSAGES from "config/constant/messages";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AntdButton from "stories/Button/AntdButton";
import AntdTable from "stories/Table/AntdTable";
import { tableColumns } from "./MockColumns";
import { tableData } from "./MockData";
dayjs.extend(customParseFormat);

const { Title } = Typography;

const DetailDataPage = ({
  data,
  onClickExportButton,
  handleChangePagination,
  pagination,
  handleChangePaginationDocumentProperties,
  handleChangePaginationDocumentRows,
  handleChangePaginationRowProperties,
  toggleEditMode,
  editMode,
  contextHolder,
  CREATE_NEW_KEY,
  onCreateNewRecord,
  form,
  EditableCell,
  mergedDetailDocumentColumns,
  mergedDocumentRowsColumns,
  mergedDocumentPropertiesColumns,
  mergedRowPropertiesColumns,
  onChange,
  tab,
  onClickReturnButton,
}) => {
  const tabOptions = [
    {
      label: "document",
      key: "documents",
      children: (
        <>
          {editMode ? (
            <Form form={form} component={false}>
              <ConfigProvider
                renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
              >
                <AntdTable
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedDetailDocumentColumns}
                  rowClassName="editable-row"
                  onChange={handleChangePagination}
                  pagination={{
                    size: "default",
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    current: pagination.pageNo,
                    showSizeChanger: false,
                  }}
                />
              </ConfigProvider>
            </Form>
          ) : (
            <ConfigProvider
              renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
            >
              <AntdTable
                columns={tableColumns.DocumentsColumns}
                dataSource={data}
                bordered={true}
                onChange={handleChangePagination}
                pagination={{
                  size: "default",
                  total: pagination.total,
                  pageSize: pagination.pageSize,
                  current: pagination.pageNo,
                  showSizeChanger: false,
                }}
              />
            </ConfigProvider>
          )}
        </>
      ),
    },
    {
      label: "documents_properties",
      key: "documents_properties",
      children: (
        <>
          {editMode ? (
            <Form form={form} component={false}>
              <ConfigProvider
                renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
              >
                <AntdTable
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedDocumentPropertiesColumns}
                  rowClassName="editable-row"
                  onChange={handleChangePaginationDocumentProperties}
                  pagination={{
                    size: "default",
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    current: pagination.pageNo,
                    showSizeChanger: false,
                  }}
                />
              </ConfigProvider>
            </Form>
          ) : (
            <ConfigProvider
              renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
            >
              <AntdTable
                columns={tableColumns.DocumentPropertiesColumns}
                dataSource={data}
                bordered={true}
                onChange={handleChangePaginationDocumentProperties}
                pagination={{
                  size: "default",
                  total: pagination.total,
                  pageSize: pagination.pageSize,
                  current: pagination.pageNo,
                  showSizeChanger: false,
                }}
              />
            </ConfigProvider>
          )}
        </>
      ),
    },
    {
      label: "documents_rows",
      key: "documents_rows",
      children: (
        <>
          {editMode ? (
            <Form form={form} component={false}>
              <ConfigProvider
                renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
              >
                <AntdTable
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedDocumentRowsColumns}
                  rowClassName="editable-row"
                  onChange={handleChangePaginationDocumentRows}
                  pagination={{
                    size: "default",
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    current: pagination.pageNo,
                    showSizeChanger: false,
                  }}
                />
              </ConfigProvider>
            </Form>
          ) : (
            <ConfigProvider
              renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
            >
              <AntdTable
                columns={tableColumns.DocumentRowsColumns}
                dataSource={data}
                bordered={true}
                onChange={handleChangePaginationDocumentRows}
                pagination={{
                  size: "default",
                  total: pagination.total,
                  pageSize: pagination.pageSize,
                  current: pagination.pageNo,
                  showSizeChanger: false,
                }}
              />
            </ConfigProvider>
          )}
        </>
      ),
    },
    {
      label: "row_properties",
      key: "row_properties",
      children: (
        <>
          {editMode ? (
            <Form form={form} component={false}>
              <ConfigProvider
                renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
              >
                <AntdTable
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={data}
                  columns={mergedRowPropertiesColumns}
                  rowClassName="editable-row"
                  onChange={handleChangePaginationRowProperties}
                  pagination={{
                    size: "default",
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    current: pagination.pageNo,
                    showSizeChanger: false,
                  }}
                />
              </ConfigProvider>
            </Form>
          ) : (
            <ConfigProvider
              renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
            >
              <AntdTable
                columns={tableColumns.RowPropertiesColumns}
                dataSource={data}
                bordered={true}
                onChange={handleChangePaginationRowProperties}
                pagination={{
                  size: "default",
                  total: pagination.total,
                  pageSize: pagination.pageSize,
                  current: pagination.pageNo,
                  showSizeChanger: false,
                }}
              />
            </ConfigProvider>
          )}
        </>
      ),
    },
  ];

  return editMode ? (
    <>
      {contextHolder}
      <div className="mt-5 container mx-auto">
        <Title level={2}>詳細データ</Title>
        <AntdButton
          type="primary"
          label={"行追加"}
          className="mt-5 mb-2 w-40"
          onClick={onCreateNewRecord}
          disabled={data.findIndex((item) => item.key == CREATE_NEW_KEY) > -1}
          autoInsertSpaceInButton={false}
        />
        <Tabs
          onChange={onChange}
          type="card"
          destroyInactiveTabPane={true}
          items={tabOptions.map((tab) => {
            return {
              label: tab.label,
              key: tab.key,
              children: tab.children,
            };
          })}
          activeKey={tab}
        />
        <div className="mt-10 mb-5 flex space-x-4 flex-row justify-end">
          <AntdButton
            type="primary"
            label={"キャンセル"}
            className="w-40"
            onClick={() => {
              toggleEditMode();
            }}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="mt-5 container mx-auto">
      <Title level={2}>詳細データ</Title>
      <div className="mb-5 flex space-y-4 flex-row justify-end">
        <AntdButton
          type="primary"
          label={"出値"}
          className="w-40"
          onClick={onClickExportButton}
          autoInsertSpaceInButton={false}
        />
      </div>
      <Tabs
        onChange={onChange}
        type="card"
        destroyInactiveTabPane={true}
        items={tabOptions.map((tab) => {
          return {
            label: tab.label,
            key: tab.key,
            children: tab.children,
          };
        })}
        activeKey={tab}
      />
      <div className="mt-10 mb-5 flex space-x-4 flex-row justify-end">
        <AntdButton
          type="primary"
          label={"戻る"}
          className="w-40"
          onClick={() => {
            onClickReturnButton();
          }}
        />
        <AntdButton
          type="primary"
          autoInsertSpaceInButton={false}
          label={"編集"}
          className="w-40"
          onClick={() => {
            toggleEditMode();
          }}
        />
      </div>
    </div>
  );
};

export default DetailDataPage;
