import { api } from "config/api.js";

class TransactionCodeService {
  getListTransactionCode = async (params) => {
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/transaction/list-by-used?${queryString}`);
  };
  createNewTransactionCode = async (params) => {
    params.isSaveLog = true;
    return api.post(`/api/transaction/create`, { ...params });
  };
}

export const transactionCodeService = new TransactionCodeService();
