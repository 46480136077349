import DetailDataPage from "stories/Pages/DetailDataPage/DetailDataPage";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { documentServices } from "services/DocumentService";
import {
  Input,
  InputNumber,
  Button,
  Form,
  notification,
  Typography,
  Divider,
  Popconfirm,
} from "antd";
import MESSAGES from "config/constant/messages";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";

const DEFAULT_PAGINATION = {
  pageNo: 1,
  pageSize: 10,
  total: 0,
};

// Handle editable table
const CREATE_NEW_KEY = "CREATE_NEW";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  let validationRules = [];

  const excludedDataIndexes = [
    "deletedFlag",
    "rowNote",
    "intValue",
    "decValue",
    "dtValue",
    "tmsValue",
  ];

  if (!excludedDataIndexes.includes(dataIndex)) {
    validationRules = [
      {
        required: true,
        message: MESSAGES.MSG_E_003(title),
      },
    ];
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={validationRules}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DetailDataContainer = () => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { referenceCode } = useParams();

  const {
    getDetailDocument,
    getDocumentProperties,
    getDocumentRows,
    getRowsProperties,
    createNewDetailDocument,
    updateDetailDocument,
    deleteDetailDocument,
    createNewDocumentRows,
    updateDocumentRows,
    deleteDocumentRows,
    createNewDocumentProperties,
    updateDocumentProperties,
    deleteDocumentProperties,
    createNewRowProperties,
    updateRowProperties,
    deleteRowProperties,
  } = documentServices;
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("documents");

  const navigate = useNavigate();

  const resetPagination = () => {
    setPagination(DEFAULT_PAGINATION);
  };

  const handleOnTabChange = (key) => {
    setTab(key);
  };

  useEffect(() => {
    setData([]);
    switch (tab) {
      case "documents":
        resetPagination();
        fetchData(getDetailDocument, DEFAULT_PAGINATION, tab);
        break;
      case "documents_properties":
        resetPagination();
        fetchData(getDocumentProperties, DEFAULT_PAGINATION, tab);
        break;
      case "documents_rows":
        resetPagination();
        fetchData(getDocumentRows, DEFAULT_PAGINATION, tab);
        break;
      case "row_properties":
        resetPagination();
        fetchData(getRowsProperties, DEFAULT_PAGINATION, tab);
        break;
      default:
        fetchData(getDetailDocument, DEFAULT_PAGINATION, tab);
        break;
    }
  }, [tab]);

  const fetchData = async (services, currentPagination, currentTab) => {
    const params = {
      ...currentPagination,
      referenceCode: referenceCode,
    };
    try {
      const res = await services(params);
      switch (currentTab) {
        case "documents":
          if (res?.content) {
            const content = res.content.map((item) => {
              item.key = item.id;
              return item;
            });
            setData(content);
            setPagination({ ...currentPagination, total: res?.totalElements });
          } else {
            console.log("NO DATA");
            setData([]);
          }
          break;
        case "documents_properties":
          if (res?.content) {
            let content = res.content.map((item) => {
              item.keyDocument = item.key;
              item.key = item.id;
              item.documentId = item.document.id;
              return item;
            });
            setData(content);
            setPagination({ ...currentPagination, total: res?.totalElements });
          } else {
            console.log("NO DATA");
            setData([]);
          }
          break;
        case "documents_rows":
          if (res?.content) {
            let content = res.content.map((item) => {
              item.key = item.id;
              item.documentId = item.document.id;
              return item;
            });
            setData(content);
            setPagination({ ...currentPagination, total: res?.totalElements });
          } else {
            console.log("NO DATA");
            setData([]);
          }
          break;
        case "row_properties":
          if (res?.content) {
            let content = res.content.map((item) => {
              item.keyDocument = item.key;
              item.key = item.id;
              item.documentId = item.document.id;
              item.documentRowId = item.documentRow.id;
              return item;
            });
            setData(content);
            setPagination({ ...currentPagination, total: res?.totalElements });
          } else {
            console.log("NO DATA");
            setData([]);
          }
          break;
        default:
          if (res?.content) {
            const content = res.content.map((item) => {
              item.key = item.id;
              return item;
            });
            setData(content);
            setPagination({ ...currentPagination, total: res?.totalElements });
          } else {
            console.log("NO DATA");
            setData([]);
          }
          break;
      }
    } catch (err) {
      console.log(err);
      setData([]);
    }
  };

  useEffect(() => {
    fetchData(getDetailDocument, pagination, tab);
  }, []);

  const handleChangePagination = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await fetchData(getDetailDocument, params, tab);
    setPagination(params);
  };

  const handleChangePaginationDocumentProperties = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await fetchData(getDocumentProperties, params, tab);
    setPagination(params);
  };

  const handleChangePaginationDocumentRows = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await fetchData(getDocumentRows, params, tab);
    setPagination(params);
  };

  const handleChangePaginationRowProperties = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await fetchData(getRowsProperties, params, tab);
    setPagination(params);
  };

  const handleOnClickExportButton = () => {
    navigate("/export-data");
  };

  const handleOnClickReturn = () => {
    navigate("/list-data");
  };

  // Editable table function
  const isEditing = (record) => record.key === editingKey;

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const editDetailDocument = (record) => {
    form.setFieldsValue({
      csvFileName: "",
      referenceCode: "",
      hlabel: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const editDocumentRows = (record) => {
    form.setFieldsValue({
      documentId: "",
      rowOrder: undefined,
      rowNote: undefined,
      deletedFlag: undefined,
      ...record,
    });
    setEditingKey(record.key);
  };

  const editDocumentProperties = (record) => {
    form.setFieldsValue({
      documentId: "",
      keyDocument: "",
      value: "",
      propertyOrder: undefined,
      intValue: undefined,
      decValue: undefined,
      dtValue: undefined,
      ocrResult: undefined,
      tmsValue: undefined,
      ...record,
    });
    setEditingKey(record.key);
  };

  const editRowProperties = (record) => {
    form.setFieldsValue({
      documentId: "",
      documentRowId: "",
      keyDocument: "",
      value: "",
      propertyOrder: undefined,
      intValue: undefined,
      decValue: undefined,
      dtValue: undefined,
      ocrResult: undefined,
      tmsValue: undefined,
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = (key) => {
    {
      if (key === CREATE_NEW_KEY) {
        let tempData = [...data];
        tempData.shift();
        setData(tempData);
      }
      setEditingKey("");
    }
  };

  const saveDetailDocument = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      if (id == CREATE_NEW_KEY) {
        await createNewDetailDocument({
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            fetchData(getDetailDocument, pagination, tab);
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });

        return;
      }

      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        await updateDetailDocument({
          id: item.id,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });
        fetchData(getDetailDocument, pagination, tab);
      } else {
        // newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const saveDocumentRows = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      if (id == CREATE_NEW_KEY) {
        await createNewDocumentRows({
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            fetchData(getDocumentRows, pagination, tab);
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });

        return;
      }

      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        await updateDocumentRows({
          id: item.id,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });
        fetchData(getDocumentRows, pagination, tab);
      } else {
        // newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const saveDocumentProperties = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      if (id == CREATE_NEW_KEY) {
        await createNewDocumentProperties({
          key: row.keyDocument,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            fetchData(getDocumentProperties, pagination, tab);
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });

        return;
      }

      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        await updateDocumentProperties({
          id: item.id,
          key: row.keyDocument,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });
        fetchData(getDocumentProperties, pagination, tab);
      } else {
        // newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const saveRowProperties = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      if (id == CREATE_NEW_KEY) {
        await createNewRowProperties({
          key: row.keyDocument,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            fetchData(getRowsProperties, pagination, tab);
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });

        return;
      }

      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        await updateRowProperties({
          id: item.id,
          key: row.keyDocument,
          ...row,
        })
          .then((res) => {
            api.success({
              message: MESSAGES.MSG_I_023,
              placement: "top",
            });
            // form.resetFields();
          })
          .catch((err) => {
            api.error({
              message: MESSAGES.MSG_E_024,
              description:
                err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "Update failed",
              placement: "top",
            });
          });
        fetchData(getRowsProperties, pagination, tab);
      } else {
        // newData.push(row);
        // setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteDetailDocumentAPI = (record) => {
    if (record.key == CREATE_NEW_KEY) {
      let tempData = [...data];
      tempData.pop();
      setData(tempData);
      return;
    }

    deleteDetailDocument(record)
      .then((res) => {
        api.success({
          message: MESSAGES.MSG_I_023,
          placement: "top",
        });
        fetchData(getDetailDocument, pagination, tab);
      })
      .catch((err) => {
        api.error({
          message: MESSAGES.MSG_E_024,
          description:
            err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Update failed",
          placement: "top",
        });
      });
  };

  const deleteDocumentRowsAPI = (record) => {
    if (record.key == CREATE_NEW_KEY) {
      let tempData = [...data];
      tempData.pop();
      setData(tempData);
      return;
    }

    deleteDocumentRows(record)
      .then((res) => {
        api.success({
          message: MESSAGES.MSG_I_023,
          placement: "top",
        });
        fetchData(getDocumentRows, pagination, tab);
      })
      .catch((err) => {
        api.error({
          message: MESSAGES.MSG_E_024,
          description:
            err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Update failed",
          placement: "top",
        });
      });
  };

  const deleteDocumentPropertiesAPI = (record) => {
    if (record.key == CREATE_NEW_KEY) {
      let tempData = [...data];
      tempData.pop();
      setData(tempData);
      return;
    }

    deleteDocumentProperties(record)
      .then((res) => {
        api.success({
          message: MESSAGES.MSG_I_023,
          placement: "top",
        });
        fetchData(getDocumentProperties, pagination, tab);
      })
      .catch((err) => {
        api.error({
          message: MESSAGES.MSG_E_024,
          description:
            err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Update failed",
          placement: "top",
        });
      });
  };

  const deleteRowPropertiesAPI = (record) => {
    if (record.key == CREATE_NEW_KEY) {
      let tempData = [...data];
      tempData.pop();
      setData(tempData);
      return;
    }

    deleteRowProperties(record)
      .then((res) => {
        api.success({
          message: MESSAGES.MSG_I_023,
          placement: "top",
        });
        fetchData(getRowsProperties, pagination, tab);
      })
      .catch((err) => {
        api.error({
          message: MESSAGES.MSG_E_024,
          description:
            err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Update failed",
          placement: "top",
        });
      });
  };

  const detailDocumentColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
      editable: true,
    },
    {
      title: "CSV File Name",
      dataIndex: "csvFileName",
      key: "csvFileName",
      editable: true,
    },
    {
      title: "Reference Code",
      dataIndex: "referenceCode",
      key: "referenceCode",
      editable: true,
    },
    {
      title: "H Label",
      dataIndex: "hlabel",
      key: "hlabel",
      editable: true,
    },
    {
      title: "Delete Flag",
      dataIndex: "deletedFlag",
      key: "deletedFlag",
      editable: true,
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
    {
      title: "操作",
      dataIndex: "操作",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveDetailDocument(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm
              title="キャンセルしますか？"
              onConfirm={() => cancel(record.key)}
              okText="はい"
              cancelText="いいえ"
            >
              <a>キャンセル</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <span className="flex items-center justify-center">
              <Typography
                disabled={editingKey !== ""}
                onClick={() => editDetailDocument(record)}
              >
                <EditOutlined className="text-xl cursor-pointer" />
              </Typography>
              <Divider type="vertical" />
              <Popconfirm
                title="データを削除しますか？"
                onConfirm={() => deleteDetailDocumentAPI(record)}
                okText="はい"
                cancelText="いいえ"
                disabled={editingKey !== ""}
              >
                <DeleteOutlined className="text-xl text-red-400 cursor-pointer" />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];

  const documentRowsColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
      editable: true,
    },
    {
      title: "Row Order",
      dataIndex: "rowOrder",
      key: "rowOrder",
      editable: true,
    },
    {
      title: "Row Note",
      dataIndex: "rowNote",
      key: "rowNote",
      editable: true,
    },
    {
      title: "Deleted Flag",
      dataIndex: "deletedFlag",
      key: "deletedFlag",
      editable: true,
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
    {
      title: "操作",
      dataIndex: "操作",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveDocumentRows(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm
              title="キャンセルしますか？"
              onConfirm={() => cancel(record.key)}
              okText="はい"
              cancelText="いいえ"
            >
              <a>キャンセル</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <span className="flex items-center justify-center">
              <Typography
                disabled={editingKey !== ""}
                onClick={() => editDocumentRows(record)}
              >
                <EditOutlined className="text-xl cursor-pointer" />
              </Typography>
              <Divider type="vertical" />
              <Popconfirm
                title="データを削除しますか？"
                onConfirm={() => deleteDocumentRowsAPI(record)}
                okText="はい"
                cancelText="いいえ"
                disabled={editingKey !== ""}
              >
                <DeleteOutlined
                  className="text-xl text-red-400 cursor-pointer"
                  style={{ width: "2rem" }}
                />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];

  const documentPropertiesColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
      editable: true,
    },
    {
      title: "Key",
      dataIndex: "keyDocument",
      key: "keyDocument",
      editable: true,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      editable: true,
    },
    {
      title: "Property Order",
      dataIndex: "propertyOrder",
      key: "propertyOrder",
      editable: true,
    },
    {
      title: "Int Value",
      dataIndex: "intValue",
      key: "intValue",
      editable: true,
    },
    {
      title: "Dec Value",
      dataIndex: "decValue",
      key: "decValue",
      editable: true,
    },
    {
      title: "Dt Value",
      dataIndex: "dtValue",
      key: "dtValue",
      editable: true,
    },
    {
      title: "Timestamp Value",
      dataIndex: "tmsValue",
      key: "tmsValue",
      editable: true,
    },
    {
      title: "OCR Result",
      dataIndex: "ocrResult",
      key: "ocrResult",
      editable: true,
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
    {
      title: "操作",
      dataIndex: "操作",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveDocumentProperties(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm
              title="キャンセルしますか？"
              onConfirm={() => cancel(record.key)}
              okText="はい"
              cancelText="いいえ"
            >
              <a>キャンセル</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <span className="flex items-center justify-center">
              <Typography
                disabled={editingKey !== ""}
                onClick={() => editDocumentProperties(record)}
              >
                <EditOutlined className="text-xl cursor-pointer" />
              </Typography>
              <Divider type="vertical" />
              <Popconfirm
                title="データを削除しますか？"
                onConfirm={() => deleteDocumentPropertiesAPI(record)}
                okText="はい"
                cancelText="いいえ"
                disabled={editingKey !== ""}
              >
                <DeleteOutlined
                  className="text-xl text-red-400 cursor-pointer"
                  style={{ width: "2rem" }}
                />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];

  const rowPropertiesColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Document Id",
      dataIndex: "documentId",
      key: "documentId",
      editable: true,
    },
    {
      title: "Document Row Id",
      dataIndex: "documentRowId",
      key: "documentRowId",
      editable: true,
    },
    {
      title: "Key",
      dataIndex: "keyDocument",
      key: "keyDocument",
      editable: true,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      editable: true,
    },
    {
      title: "Property Order",
      dataIndex: "propertyOrder",
      key: "propertyOrder",
      editable: true,
    },
    {
      title: "Int Value",
      dataIndex: "intValue",
      key: "intValue",
      editable: true,
    },
    {
      title: "Dec Value",
      dataIndex: "decValue",
      key: "decValue",
      editable: true,
    },
    {
      title: "Dt Value",
      dataIndex: "dtValue",
      key: "dtValue",
      editable: true,
    },
    {
      title: "Timestamp Value",
      dataIndex: "tmsValue",
      key: "tmsValue",
      editable: true,
    },
    {
      title: "OCR Result",
      dataIndex: "ocrResult",
      key: "ocrResult",
      editable: true,
    },
    {
      title: "Update Time",
      dataIndex: "updTms",
      key: "updTms",
    },
    {
      title: "Update User",
      dataIndex: "updUser",
      key: "updUser",
    },
    {
      title: "操作",
      dataIndex: "操作",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveRowProperties(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              保存
            </Typography.Link>
            <Popconfirm
              title="キャンセルしますか？"
              onConfirm={() => cancel(record.key)}
              okText="はい"
              cancelText="いいえ"
            >
              <a>キャンセル</a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <span className="flex items-center justify-center">
              <Typography
                disabled={editingKey !== ""}
                onClick={() => editRowProperties(record)}
              >
                <EditOutlined className="text-xl cursor-pointer" />
              </Typography>
              <Divider type="vertical" />
              <Popconfirm
                title="データを削除しますか？"
                onConfirm={() => deleteRowPropertiesAPI(record)}
                okText="はい"
                cancelText="いいえ"
                disabled={editingKey !== ""}
              >
                <DeleteOutlined
                  className="text-xl text-red-400 cursor-pointer"
                  style={{ width: "2rem" }}
                />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];
  const mergedDetailDocumentColumns = detailDocumentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "id" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedDocumentRowsColumns = documentRowsColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "documentId" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedDocumentPropertiesColumns = documentPropertiesColumns.map(
    (col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "documentId" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
  );

  const mergedRowPropertiesColumns = rowPropertiesColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "documentId" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCreateNewRecord = () => {
    form.resetFields();
    let tempData = [...data];
    switch (tab) {
      case "documents":
        tempData.unshift({
          key: CREATE_NEW_KEY,
          documentType: undefined,
          csvFileName: undefined,
          referenceCode: undefined,
          hlabel: undefined,
          deletedFlag: undefined,
        });
        break;
      case "documents_rows":
        tempData.unshift({
          key: CREATE_NEW_KEY,
          documentId: undefined,
          rowOrder: undefined,
          rowNote: undefined,
          deletedFlag: undefined,
        });
        break;
      case "documents_properties":
        tempData.unshift({
          key: CREATE_NEW_KEY,
          documentId: undefined,
          keyDocument: undefined,
          value: undefined,
          propertyOrder: undefined,
          intValue: undefined,
          decValue: undefined,
          dtValue: undefined,
          ocrResult: undefined,
          tmsValue: undefined,
          deletedFlag: undefined,
        });
        break;
      case "row_properties":
        tempData.unshift({
          key: CREATE_NEW_KEY,
          documentId: undefined,
          documentRowId: undefined,
          keyDocument: undefined,
          value: undefined,
          propertyOrder: undefined,
          intValue: undefined,
          decValue: undefined,
          dtValue: undefined,
          ocrResult: undefined,
          tmsValue: undefined,
        });
        break;
      default:
        break;
    }
    setData(tempData);
  };

  useEffect(() => {
    if (data.length > 0) {
      let recordCreateNewIndex = data.findIndex(
        (item) => item.key == CREATE_NEW_KEY
      );
      if (recordCreateNewIndex > -1) {
        setEditingKey(CREATE_NEW_KEY);
      } else {
        setEditingKey("");
      }
    }
  }, [data]);

  return (
    <>
      <DetailDataPage
        contextHolder={contextHolder}
        data={data}
        onClickExportButton={handleOnClickExportButton}
        onClickReturnButton={handleOnClickReturn}
        handleChangePagination={handleChangePagination}
        handleChangePaginationDocumentProperties={
          handleChangePaginationDocumentProperties
        }
        handleChangePaginationDocumentRows={handleChangePaginationDocumentRows}
        handleChangePaginationRowProperties={
          handleChangePaginationRowProperties
        }
        resetPagination={resetPagination}
        pagination={pagination}
        toggleEditMode={toggleEditMode}
        editMode={editMode}
        CREATE_NEW_KEY={CREATE_NEW_KEY}
        onCreateNewRecord={onCreateNewRecord}
        form={form}
        EditableCell={EditableCell}
        mergedDetailDocumentColumns={mergedDetailDocumentColumns}
        mergedDocumentRowsColumns={mergedDocumentRowsColumns}
        mergedDocumentPropertiesColumns={mergedDocumentPropertiesColumns}
        mergedRowPropertiesColumns={mergedRowPropertiesColumns}
        onChange={handleOnTabChange}
        tab={tab}
      />
    </>
  );
};

export default DetailDataContainer;
