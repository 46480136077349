import { EditOutlined } from "@ant-design/icons";
import {
  Divider,
  Popconfirm,
  Typography,
  message,
  notification,
  Modal,
} from "antd";
import ResetPasswordIcon from "assets/icon/ResetPassword";
import CreateUserModal from "components/Modal/CreateUserModal";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { userServices } from "services/UserServices";
import AntdButton from "stories/Button/AntdButton";
import AntdTable from "stories/Table/AntdTable";
import MESSAGES from "config/constant/messages";
import HTTP_STATUS_CONTSTANTS from "config/constant/httpStatus";
const { confirm } = Modal;

const { Title } = Typography;

const UserManagementContainer = () => {
  const [api, contextHolder] = notification.useNotification();
  const { getListUser, resetPassword, deleteUser } = userServices;
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [selectedUserKeys, setSelectedUserKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userIdList, setUserIdList] = useState(null);
  const [editingRecord, setEditingRecord] = useState({});
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  });
  const [isEdit, setIsEdit] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUserKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.id == Cookies.get("userId"),
    }),
  };

  useEffect(() => {
    getListUserAPI(pagination);
  }, []);

  useEffect(() => {
    const userIdList = selectedRows.map((row) => row.userId).join("、 ");
    setUserIdList(userIdList);
  }, [selectedRows]);

  const getListUserAPI = async (currentPagination) => {
    getListUser(currentPagination)
      .then((res) => {
        if (res?.content) {
          let content = res.content.map((item) => {
            item.key = item.id;
            return item;
          });
          setData(content);
          setPagination({ ...currentPagination, total: res?.totalElements });
        } else {
          console.log("NO DATA");
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      });
  };

  const onResetPassword = (record) => {
    resetPassword(record)
      .then((res) => {
        api.success({
          message: `${record.userId}の${MESSAGES.MSG_I_020}`,
          placement: "top",
        });
      })
      .catch((err) => {
        api.error({
          message: "パスワードのリセットに失敗しました。",
          description: err?.data?.message || err?.data?.messages,
          placement: "top",
        });
      });
  };

  const handleChangePagination = async (newPagination) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
    };
    await getListUserAPI(params);
    setPagination(params);
  };

  const columns = [
    {
      title: "ユーザーID",
      dataIndex: "userId",
      key: "userId",
      width: "70%",
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "25%",
      align: "center",
      render: (_, record) => {
        return (
          <span className="flex items-center justify-center">
            <Typography
              className={"cursor-pointer flex"}
              onClick={() => {
                setIsEdit(true);
                onEditRecord(record);
              }}
            >
              <EditOutlined className="text-xl" />
            </Typography>
            <Divider type="vertical" />
            <Popconfirm
              title={MESSAGES.MSG_W_022}
              onConfirm={() => onResetPassword(record)}
              cancelText="いいえ"
              okText="はい"
            >
              <Typography className={"cursor-pointer"}>
                <ResetPasswordIcon
                  aria-label="パスワードリセット"
                  fill={"currentColor"}
                />
              </Typography>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const onCreateNewRecord = () => {
    //link to create page
    setIsEdit(false);
    showModal();
  };

  const onEditRecord = (record) => {
    setEditingRecord(record);
    showModal();
  };

  const onDeleteUser = () => {
    if (selectedUserKeys.length == 0) {
      message.error(MESSAGES.MSG_E_021);
    } else {
      confirm({
        // title: `${userIdList}を削除しますか？`,
        title: (
          <>
            <Typography className="text-base font-thin">
              {userIdList}を削除しますか？
            </Typography>
          </>
        ),
        onOk() {
          deleteUser({ id: selectedUserKeys.join(",") })
            .then((res) => {
              if (res?.code === HTTP_STATUS_CONTSTANTS.SUCCESS) {
                message.success(MESSAGES.MSG_I_010(userIdList));
                setUserIdList("");
                setSelectedUserKeys([]);
                getListUserAPI(pagination);
              } else {
                message.error("ユーザー削除に失敗しました。");
              }
            })
            .catch((err) => {
              message.error("ユーザー削除に失敗しました。");
            });
        },
        onCancel() {},
        cancelText: "いいえ",
        okText: "はい",
        cancelButtonProps: { className: "text-white" },
      });
    }
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="mt-5 mx-10">
      {contextHolder}
      <Title level={2}>ユーザー管理</Title>
      <CreateUserModal
        isEdit={isEdit}
        isModalOpen={openModal}
        onCloseModal={closeModal}
        editingRecord={editingRecord}
        setEditingRecord={setEditingRecord}
        getListUserAPI={getListUserAPI}
        pagination={pagination}
      />
      <div className="container mx-auto">
        <AntdButton
          label="追加"
          className="mb-4 text-white"
          type={"primary"}
          onClick={onCreateNewRecord}
          autoInsertSpaceInButton={false}
        />
        <AntdButton
          label="削除"
          className="ml-2 mb-4 text-white"
          type={"primary"}
          onClick={onDeleteUser}
          autoInsertSpaceInButton={false}
        />
        <AntdTable
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          onChange={handleChangePagination}
          pagination={{
            size: "default",
            total: pagination.total,
            pageSize: pagination.pageSize,
            current: pagination.pageNo,
          }}
        />
      </div>
    </div>
  );
};
export default UserManagementContainer;
