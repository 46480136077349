import { api } from "config/api.js";

class ProcessService {
    getListProcess = async (params) => {
        Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
        const queryString = new URLSearchParams(params).toString();
        return api.get(`/api/transaction-process/list-process?${queryString}`);
    };
    getListProcessStatus = async () => {
        return api.get(`/api/transaction-process/list/status`);
    };
    downloadExcelFile = async (params) => {
        const { transactionTypeId, transactionCode } = params
        return api.get(`/api/storage/excel/${transactionCode}/${transactionTypeId}`, { responseType: "blob" })
    }
}

export const processServices = new ProcessService();
