import React, { useEffect } from "react";
import AntdInput from "stories/Input/AntdInput";
import AntdTable from "stories/Table/AntdTable";
import { useState } from "react";
import {
  Row,
  Col,
  Form,
  message,
  Modal,
  Radio,
  Select,
  Typography,
} from "antd";
import AntdButton from "stories/Button/AntdButton";
import AntdRadio from "../../stories/Radio/AntdRadio";
import Cookies from "js-cookie";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import CreateFolderModal from "../../components/Modal/CreateFolderModal";
import { webService } from "../../services/WebService";
import MESSAGES from "config/constant/messages";
import HTTP_STATUS_CONTSTANTS from "config/constant/httpStatus";

const options = [
  {
    value: 2,
    label: "輸入",
  },
  {
    value: 1,
    label: "輸出",
  },
];

const WebServicesContainer = () => {
  const { confirm } = Modal;
  const { Title } = Typography;
  const [form] = Form.useForm(); // Ant Design Form hook
  const [sortInfo, setSortInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [data, setData] = useState([]);
  const { getListFolder, deleteFolder, getListSearchFolder } = webService;
  const [typeValue, setTypeValue] = useState(null);
  const [transactionCodeValue, setTransactionCodeValue] = useState("");
  const [statusValue, setStatusValue] = useState(null);
  const [transListName, setTransListName] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  });
  const [formValues, setFormValues] = useState({
    type: null,
    transactionCode: "",
    usedFlag: null,
  });
  const onFormValuesChange = (changedValues, allValues) => {
    setFormValues(allValues);
  };
  const onSearchButtonClick = async () => {
    await handleTableChange(pagination, formValues);
  };

  useEffect(() => {
    const transListName = selectedRows
      .map((row) => {
        return row.transactionCode;
      })
      .join("、 ");
    setTransListName(transListName);
  }, [selectedRows]);

  useEffect(() => {
    getListFolderAPI(pagination);
  }, []);

  const getListFolderAPI = async (currentPagination) => {
    try {
      const response = await getListFolder(currentPagination);
      if (response?.content) {
        const formattedData = response.content.map((item) => {
          return {
            key: item.id, // Assuming transactionCode.id is unique
            transactionType: item.transactionType.type, // Assuming transactionType is available
            transactionCode: item.transactionCode,
            usedFlag: item.usedFlag === true ? "使用中" : "未使用",
          };
        });
        setData(formattedData);
        setPagination({ ...currentPagination, total: response?.totalElements });
      } else {
        console.log("NO DATA");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      setData([]);
    }
  };

  const showModalCreate = () => {
    setOpenModal(true);
  };
  const showModalEdit = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    // refreshData();
  };

  const onDeleteFolder = () => {
    if (selectedRowKeys.length === 0) {
      message.error(MESSAGES.MSG_E_023);
    } else {
      confirm({
        title: (
          <>
            <Typography className="text-base font-thin">
              {transListName}を削除しますか?
            </Typography>
          </>
        ),
        onOk() {
          deleteFolder({ id: selectedRowKeys.join(",") })
            .then((res) => {
              if (res?.code === HTTP_STATUS_CONTSTANTS.SUCCESS) {
                message.success(MESSAGES.MSG_I_010(transListName));
                setTransListName("");
                setSelectedRowKeys([]);
                getListFolderAPI(pagination);
                // refreshData();
              } else {
                message.error("フォルダーの削除に失敗しました。");
              }
            })
            .catch((err) => {
              message.error("フォルダーの削除に失敗しました。");
            });
        },
        onCancel() {},
        cancelText: "いいえ",
        okText: "はい",
        cancelButtonProps: { className: "text-white" },
      });
    }
  };
  const handleTransactionCodeChange = (e) => {
    setTransactionCodeValue(e.target.value);
  };
  // const onSearchFolder = async () => {
  //   try {
  //     // Validate form fields
  //     const values = await form.validateFields();

  //     // Cập nhật các giá trị tìm kiếm vào state
  //     if ("type" in values) {
  //       setTypeValue(values["type"]);
  //     }
  //     if ("transactionCode" in values) {
  //       setTransactionCodeValue(values["transactionCode"].trim());
  //     }
  //     if ("usedFlag" in values) {
  //       setStatusValue(values["usedFlag"]);
  //     }

  //     const searchParams = {
  //       pageNo: pagination.pageNo,
  //       pageSize: pagination.pageSize,
  //       typeSort: sortInfo.field,
  //       transactionType: typeValue !== null ? typeValue : undefined,
  //       transactionCode:
  //         transactionCodeValue.trim() !== ""
  //           ? transactionCodeValue.trim()
  //           : undefined,
  //       usedFlag: statusValue !== null ? statusValue : undefined, // ... (other fields)
  //     };

  //     const response = await getListFolder(searchParams);

  //     if (response?.content) {
  //       const formattedData = response.content.map((item) => {
  //         return {
  //           key: item.id,
  //           transactionType: item.transactionType.type,
  //           transactionCode: item.transactionCode,
  //           usedFlag: item.usedFlag === true ? "使用中" : "未使用",
  //         };
  //       });
  //       setData(formattedData);
  //       setPagination({ ...pagination, total: response?.totalElements });
  //     } else {
  //       console.log("NO DATA");
  //       setData([]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setData([]);
  //   }
  // };

  const handleTableChange = async (newPagination, filters, sorter) => {
    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
      sortBy: sorter && sorter.column ? sorter.columnKey : sorter?.columnKey,
      transactionType: formValues.transactionType ?? undefined,      
      // transactionCode:
      //   transactionCodeValue.trim() !== ""
      //     ? transactionCodeValue.trim()
      //     : undefined,
      transactionCode:
        transactionCodeValue.trim() ?? undefined,
      usedFlag: statusValue !== null ? statusValue : undefined,
    };

    console.log(params.transactionCode)

    if (sorter && Object.keys(sorter).length > 0) {
      const sortOrder = sorter?.order === "ascend" ? "asc" : "desc";
      params.orderBy = sortOrder;
    } else {
      params.orderBy = undefined;
    }

    try {
      const response = await getListFolder(params);

      if (response?.content) {
        const formattedData = response.content.map((item) => ({
          key: item.id,
          transactionType: item.transactionType.type,
          transactionCode: item.transactionCode,
          usedFlag: item.usedFlag === true ? "使用中" : "未使用",
        }));
        setData(formattedData);
        setPagination({ ...params, total: response?.totalElements });
      } else {
        console.log("NO DATA");
        setData([]);
      }
    } catch (error) {
      console.error(error);
      setData([]);
    }
  };

  const columns = [
    {
      title: "種別",
      dataIndex: "transactionType",
      key: "transactionType",
      width: "25%",
      requireInput: true,
      inputType: "text",
      sorter: true,
    },
    {
      title: "取引コード",
      dataIndex: "transactionCode",
      key: "transactionCode",
      editable: true,
      requireInput: false,
      inputType: "text",
      width: "30%",
      sorter: true,
    },
    {
      title: "ステータス",
      dataIndex: "usedFlag",
      key: "usedFlag",
      width: "30%",
      sorter: true,
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <span className="flex items-center justify-center">
          <Typography
            className={"cursor-pointer flex"}
            onClick={() => edit(record)}
          >
            <EditOutlined className="text-xl" />
          </Typography>
        </span>
      ),
    },
  ];
  const edit = (record) => {
    const { key, transactionType, transactionCode } = record;
    setEditingRecord({ key, transactionType, transactionCode });
    showModalCreate();
  };
  const onCreateNewRecord = () => {
    showModalCreate();
  };
  const onValuesChange = (changedValues, allValues) => {
    // Update state values based on form changes
    if ("type" in changedValues) {
      setTypeValue(changedValues["type"]);
    }
    if ("usedFlag" in changedValues) {
      setStatusValue(changedValues["usedFlag"]);
    }
  };

  return (
    <div className="mt-5 mx-10">
      <Title level={2}>Webフォルダー管理</Title>
      <CreateFolderModal
        isModalOpen={openModal}
        onCloseModal={closeModal}
        editingRecord={editingRecord}
        setEditingRecord={setEditingRecord}
        getListFolderAPI={getListFolderAPI}
        pagination={pagination}
        options={options}
      />
      <div className="inputField">
        <div className="mt-2">
          <Form
            className={"form-label-text-left"}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onValuesChange={onFormValuesChange}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label={"種別"}
                  name="transactionType"
                  initialValue={null}
                  colon={false}
                >
                  <Radio.Group>
                    <Radio value="2">輸入</Radio>
                    <Radio value="1">輸出</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  className="form-label-text-left"
                  label="取引コード"
                  name="transactionCode"
                  initialValue=""
                  colon={false}
                >
                  <AntdInput
                    className="text-left keep-border"
                    style={{ width: "50%" }}
                    size={"middle"}
                    onChange={handleTransactionCodeChange}
                    allowClear={true}
                    maxLength={255}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={"ステータス"}
                  name="usedFlag"
                  initialValue={null}
                  colon={false}
                >
                  <Select
                    className="text-left"
                    style={{ width: "50%" }}
                    addonBefore={"ステータス"}
                    onChange={(value) => setStatusValue(value)}
                    options={[
                      { value: true, label: "使用中" },
                      { value: false, label: "未使用" },
                    ]}
                  />
                  <AntdButton
                    label="検索"
                    className="text-white ml-2 "
                    type={"primary"}
                    style={{ width: "30%" }}
                    autoInsertSpaceInButton={false}
                    onClick={onSearchButtonClick}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="group_button">
          <AntdButton
            label="追加"
            className="mb-2 text-white"
            type={"primary"}
            git
            style={{ width: "10%" }}
            autoInsertSpaceInButton={false}
            onClick={onCreateNewRecord}
          />
          <AntdButton
            label="削除"
            className="ml-2 text-white"
            type={"primary"}
            style={{ width: "10%" }}
            autoInsertSpaceInButton={false}
            onClick={onDeleteFolder}
          />
        </div>
        <div className="table_display" style={{ width: "100%" }}>
          <Form component={false}>
            <AntdTable
              dataSource={data}
              columns={columns}
              bordered
              showSorterTooltip={false}
              rowClassName="editable-row"
              rowSelection={{
                selectedRowKeys,
                onChange: (keys, selectedRows) => {
                  setSelectedRowKeys(keys);
                  setSelectedRows(selectedRows);
                },
              }}
              sortDirections={["ascend", "descend"]}
              locale={{
                triggerDesc: "クリックして、降順で並べ替えます。",
                triggerAsc: "クリックして、昇順で並べ替えます。",
                cancelSort: "クリックして、並べ替えをキャンセルします。",
                emptyText: "データがありません。",
              }}
              pagination={{
                size: "default",
                total: pagination.total,
                pageSize: pagination.pageSize,
                current: pagination.pageNo,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 50, 100],
                locale: { items_per_page: "/ページ" },
              }}
              onChange={(pagination, filters, sorter) => {
                handleTableChange(pagination, filters, sorter);
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default WebServicesContainer;
