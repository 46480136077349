import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Select,
  Space,
  Typography,
  message,
  Empty,
} from "antd";
import HTTP_STATUS_CONTSTANTS from "config/constant/httpStatus";
import MESSAGES from "config/constant/messages";
import { JAPAN_TIMEZONE } from "config/constant/timezone";
import moment from "moment";
import { useEffect, useState } from "react";
import { transactionCodeService } from "services/TransactionCodeService";
import { uploadServices } from "services/UploadService";
import AntdButton from "stories/Button/AntdButton";
import AntdInput from "stories/Input/AntdInput";
import AntdRadio from "stories/Radio/AntdRadio";
import AntdTable from "stories/Table/AntdTable";
import AntdUploadButton from "stories/UploadButton/AntdUploadButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Title } = Typography;

const options = [
  {
    value: 2,
    label: "輸入",
  },
  {
    value: 1,
    label: "輸出",
  },
];

const MAX_TRANSACTION_CODE_LENGTH = 255;

const UploadContainer = () => {
  // handle upload multiple files
  const [uploadList, setUploadList] = useState([]);
  const [stringResult, setStringResult] = useState("");
  const [data, setData] = useState([]);
  const [referenceCode, setReferenceCode] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  });
  const [radioValue, setRadioValue] = useState(2);
  const [transactionCodeOptions, setOptions] = useState([]);
  const [transactionCode, setNewTransactionCode] = useState("");

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [newPagination, setNewPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  });

  const { getListPDFUpload, uploadPDF } = uploadServices;
  const { createNewTransactionCode, getListTransactionCode } =
    transactionCodeService;

  useEffect(() => {
    getListPDFAPI(pagination);
  }, []);

  const clearFileUpload = () => {
    setStringResult("");
    setUploadList([]);
  };

  useEffect(() => {
    setReferenceCode(undefined);
    getListTransactionCodeAPI(radioValue);
  }, [radioValue]);

  const getListTransactionCodeAPI = async (transactionType) => {
    try {
      getListTransactionCode({
        // Fetching all unused transactionCode
        pageNo: 0,
        transactionType: transactionType,
      })
        .then((res) => {
          if (res) {
            const transactionCodeOptions = res.map((item) => {
              return {
                value: item.transactionCode,
                label: item.transactionCode,
              };
            });
            setOptions(transactionCodeOptions);
          } else {
            console.log("NO DATA");
            setOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          setOptions([]);
        });
    } catch (error) {
      console.log(error);
      setOptions([]);
    }
  };

  const getListPDFAPI = async (currentPagination) => {
    getListPDFUpload({ ...currentPagination })
      .then((res) => {
        if (res?.content) {
          const content = res.content.map((item) => ({
            ...item,
            key: item.id,
          }));
          setData(content);
          setPagination({ ...currentPagination, total: res?.totalElements });
        } else {
          console.log("NO DATA");
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      });
  };

  useEffect(() => {
    filterSorterFunction(newPagination, filteredInfo, sortedInfo);
  }, [newPagination, filteredInfo, sortedInfo]);

  const filterSorterFunction = async (newPagination, filters, sorter) => {
    const filterTransactionType = filters.transactionType;
    const filterPdfFileName = filters.pdfFileName;
    const filterTransactionCode = filters.transactionCode;
    const filterUploadTms = filters.uplTms;

    const sortOrder = sorter?.order === "ascend" ? "asc" : "desc";

    let params = {
      ...pagination,
      pageNo: newPagination.current ? newPagination.current : 1,
      pageSize: newPagination.pageSize ? newPagination.pageSize : 10,
      sortBy: sorter.column ? sorter.columnKey : "uplTms",
      orderBy: sortOrder,
    };

    if (filterTransactionType && filterTransactionType.length > 0) {
      params.transactionType = filterTransactionType;
    } else {
      params.transactionType = undefined;
    }

    if (filterPdfFileName && filterPdfFileName.length > 0) {
      params.pdfFileName = filterPdfFileName;
    } else {
      params.pdfFileName = undefined;
    }

    if (filterTransactionCode && filterTransactionCode.length > 0) {
      params.transactionCode = filterTransactionCode;
    } else {
      params.transactionCode = undefined;
    }

    if (filterUploadTms && filterUploadTms.length > 0) {
      params.date = filterUploadTms;
    } else {
      params.date = undefined;
    }

    await getListPDFAPI(params);
    setPagination(params);
  };

  const handleTableChange = async (newPagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setNewPagination(newPagination);
  };

  useEffect(() => {
    if (uploadList.length > 0) {
      setStringResult(uploadList.map((file) => `${file.name}`).join("、 "));
    } else {
      setStringResult("");
    }
  }, [uploadList]);

  const handleUpload = async () => {
    // handle upload multiple files
    if (uploadList.length < 1) {
      message.error(MESSAGES.MSG_E_017);
    } else {
      if (referenceCode) {
        try {
          let formData = new FormData();

          uploadList.forEach((file) => {
            formData.append("file", file);
          });

          uploadPDF({
            transactionCode: referenceCode,
            transactionTypeId: radioValue,
            formData: formData,
          })
            .then((res) => {
              if (res.code == HTTP_STATUS_CONTSTANTS.SUCCESS) {
                getListPDFAPI(pagination);
                clearFileUpload();
                setReferenceCode(undefined);
                message.success(
                  res?.message ??
                    res?.messages ??
                    res?.data?.message ??
                    res?.data?.messages ??
                    "Upload Success"
                );
              } else {
                message.error(
                  res?.message ??
                    res?.messages ??
                    res?.data?.message ??
                    res?.data?.messages ??
                    "Upload Failed"
                );
              }
            })
            .catch((err) => {
              message.error(
                err?.message ??
                  err?.messages ??
                  err?.data?.message ??
                  err?.data?.messages ??
                  "Upload Failed"
              );
            });
        } catch (error) {
          return error?.message;
        }
      } else {
        message.error(MESSAGES.MSG_E_003("取引コード"));
      }
    }
  };

  const handleNoUpload = async () => {
    return;
  };

  const onRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const onSelectChange = (value) => {
    setReferenceCode(value);
  };

  const onNewTransactionCodeChange = (event) => {
    setNewTransactionCode(event.target.value);
  };

  const createNewTransactionCodeAPI = () => {
    if (transactionCode !== "") {
      try {
        createNewTransactionCode({
          transactionCode: transactionCode,
          transactionType: radioValue,
        })
          .then((res) => {
            if (res.code == HTTP_STATUS_CONTSTANTS.SUCCESS) {
              message.success(
                res?.message ??
                  res?.messages ??
                  res?.data?.message ??
                  res?.data?.messages ??
                  "取引コードの作成に失敗しました。"
              );
              getListTransactionCodeAPI(radioValue);
            } else {
              message.error(
                res?.message ??
                  res?.messages ??
                  res?.data?.message ??
                  res?.data?.messages ??
                  "取引コードの作成に失敗しました。"
              );
            }
          })
          .catch((err) => {
            message.error(
              err?.message ??
                err?.messages ??
                err?.data?.message ??
                err?.data?.messages ??
                "取引コードの作成に失敗しました。"
            );
          });
      } catch (error) {
        return error?.message;
      }
      setNewTransactionCode("");
    } else {
      message.error(MESSAGES.MSG_E_003("取引コード"));
    }
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      if (dataIndex === "uplTms") {
        return (
          <div className="p-2">
            <Space>
              <DatePicker
                placeholder={"日付を選択する"}
                format={"YYYY-MM-DD"}
                onChange={(e) => {
                  setSelectedKeys(e ? [e.format("YYYY-MM-DDT00:00:00Z")] : []);
                }}
                allowClear={true}
              />
              <AntdButton
                type="primary"
                onClick={() => confirm()}
                size="small"
                className="w-24"
                autoInsertSpaceInButton={false}
                label={"検索"}
              ></AntdButton>
            </Space>
          </div>
        );
      } else if (dataIndex === "transactionType") {
        return (
          <div className="p-3">
            <Space direction="vertical" className="flex">
              <Checkbox.Group
                options={options}
                onChange={(value) => setSelectedKeys(value ? value : [])}
              />
              <Divider className="m-0" />
              <Space className="pt-2">
                <AntdButton
                  type="primary"
                  onClick={() => {
                    confirm();
                  }}
                  size="small"
                  className="w-32"
                  autoInsertSpaceInButton={false}
                  label={"検索"}
                />
              </Space>
            </Space>
          </div>
        );
      } else {
        return (
          <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
            <AntdInput
              placeholder={`${title}を入力`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => {
                confirm();
              }}
              className="mb-2 block w-44"
            />
            <Space>
              <AntdButton
                type="primary"
                onClick={() => {
                  confirm();
                }}
                size="small"
                className="w-44"
                autoInsertSpaceInButton={false}
                label={"検索"}
              />
            </Space>
          </div>
        );
      }
    },
    filterIcon: (filtered) => {
      return (
        <FilterOutlined
          style={{
            color: filtered ? "#1677ff" : undefined,
          }}
        />
      );
    },
  });

  const clearAll = () => {
    setFilteredInfo({});
  };

  const uploadedColumns = [
    {
      filteredValue: filteredInfo.pdfFileName || null,
      sortOrder: sortedInfo.columnKey === "pdfFileName" && sortedInfo.order,
      title: "PDFファイル名称",
      dataIndex: "pdfFileName",
      key: "pdfFileName",
      width: "40%",
      sorter: true,
      render: (text) => <p>{text}</p>,
      ...getColumnSearchProps("pdfFileName", "PDFファイル名称"),
    },
    {
      filteredValue: filteredInfo.uplTms || null,
      sortOrder: sortedInfo.columnKey === "uplTms" && sortedInfo.order,
      title: "アップロード日付",
      dataIndex: "uplTms",
      key: "uplTms",
      width: "25%",
      sorter: true,
      render: (date) => {
        const originalDate = moment(date);
        const formattedDate = originalDate.format("YYYY-MM-DD HH:mm:ss");
        const japanTime = dayjs(formattedDate)
          .tz(JAPAN_TIMEZONE)
          .format("YYYY/MM/DD");
        return <p>{japanTime}</p>;
      },
      ...getColumnSearchProps("uplTms", "uploadTime"),
    },
    {
      filteredValue: filteredInfo.transactionCode || null,
      sortOrder: sortedInfo.columnKey === "transactionCode" && sortedInfo.order,
      title: "取引コード",
      dataIndex: "transactionCode",
      key: "transactionCode",
      sorter: true,
      width: "25%",
      ...getColumnSearchProps("transactionCode", "取引コード"),
    },
    {
      filteredValue: filteredInfo.transactionType || null,
      sortOrder: sortedInfo.columnKey === "transactionType" && sortedInfo.order,
      title: "種別",
      dataIndex: "transactionType",
      key: "transactionType",
      width: "10%",
      sorter: true,
      render: (type) => {
        return <p>{type}</p>;
      },
      ...getColumnSearchProps("transactionType", "種別"),
    },
  ];

  const isFilterEmpty = (obj) => {
    return Object.values(obj).every((value) => value === null);
  };

  const handleBeforeUpload = (file, fileList) => {
    let findDuplicateFile = uploadList.findIndex(
      (fileState) => fileState.name == file.name
    );
    if (findDuplicateFile > -1) {
      return false;
    }
    let tempUploadList = [...uploadList];
    setUploadList([...tempUploadList, ...fileList]);
    return true;
  };

  return (
    <div className="mt-5 mx-10">
      <Title level={2}>PDFファイルアップロード</Title>
      <div className="container">
        <div className="mt-7 mb-5 flex space-y-4 flex-col items-left justify-left w-1/3">
          <div className="flex space-x-20 px-2.5 flex-rows">
            <Typography>種別</Typography>
            <AntdRadio
              options={options}
              value={radioValue}
              onChange={onRadioChange}
            />
          </div>
          <div className="flex space-x-10 px-2.5 flex-rows">
            <Typography className="py-1">取引コード</Typography>
            <ConfigProvider
              renderEmpty={() => (
                <div className="">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>{MESSAGES.MSG_I_015}</span>}
                  />
                </div>
              )}
            >
              <Select
                style={{ width: "calc(100% - 8.3rem)" }}
                placeholder="取引コードを選択"
                value={referenceCode}
                showSearch={true}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider className="mt-2 mb-0 mx-0" />
                    <Space className="create-transition-code">
                      <AntdInput
                        maxLength={MAX_TRANSACTION_CODE_LENGTH}
                        placeholder="取引コードを新規作成"
                        value={transactionCode}
                        onChange={onNewTransactionCodeChange}
                        allowClear={true}
                      />
                      <AntdButton
                        type="primary"
                        onClick={createNewTransactionCodeAPI}
                        label={"作成"}
                        autoInsertSpaceInButton={false}
                      ></AntdButton>
                    </Space>
                  </>
                )}
                onChange={onSelectChange}
                options={transactionCodeOptions}
              />
            </ConfigProvider>
          </div>
          <div className="flex">
            <AntdInput
              addonBefore={
                <>
                  <AntdUploadButton
                    multiple={true}
                    accept={".pdf"}
                    customRequest={handleNoUpload}
                    // fileList={uploadList}
                    label={"ファイル選択"}
                    className="select-file text-white"
                    showUploadList={false}
                    beforeUpload={handleBeforeUpload}
                  />
                </>
              }
              style={{ width: "calc(100% - 2rem)" }}
              size={"middle"}
              disabled={true}
              value={stringResult}
            />
            <DeleteOutlined
              className="text-xl text-red-400 flex justify-center cursor-pointer"
              onClick={() => clearFileUpload()}
              style={{ width: "2rem" }}
            />
          </div>
          <div className="mt-5 mb-10 ml-auto">
            <AntdButton
              type="primary"
              onClick={handleUpload}
              label={"アップロード"}
            />
          </div>
        </div>
      </div>
      {!isFilterEmpty(filteredInfo) ? (
        <div className="mt-0 mb-3 flex flex-row-reverse">
          <AntdButton
            className="w-44"
            type="primary"
            label={"フィルターリセット"}
            onClick={clearAll}
          />
        </div>
      ) : (
        <></>
      )}
      <ConfigProvider
        renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
      >
        <AntdTable
          locale={{
            triggerDesc: "クリックして、降順で並べ替えます。",
            triggerAsc: "クリックして、昇順で並べ替えます。",
            cancelSort: "クリックして、並べ替えをキャンセルします。",
          }}
          columns={uploadedColumns}
          dataSource={data}
          bordered={true}
          onChange={handleTableChange}
          pagination={{
            size: "default",
            total: pagination.total,
            pageSize: pagination.pageSize,
            current: pagination.pageNo,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 50, 100],
            locale: { items_per_page: "/ページ" },
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default UploadContainer;
