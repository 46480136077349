import HTTP_STATUS_CONTSTANTS from "./constant/httpStatus";
import MESSAGES from "./constant/messages";
import { configs } from "./index.js";
import axios from "axios";
import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';
import { store } from 'store';
import * as actionType from 'store/actions';

const REQUEST_SAVE_LOG = ['post', 'put', 'patch', 'delete']

const api = axios.create({
    baseURL: configs.REACT_APP_BACKEND_URL,
    withCredentials: false,
});

api.interceptors.request.use((config) => {
    store.dispatch({ type: actionType.LOADING_SPIN, payload: { loading: true } })
    const token = Cookies.get("access_token");
    config.headers.Authorization = "Bearer " + token;
    config.rawData = config.data
    return config;
});

api.interceptors.response.use(
    (response) => {
        checkUserSaveLog(response)
        store.dispatch({ type: actionType.LOADING_SPIN, payload: { loading: false } })
        return response.data;
    },
    (error) => {
        console.log(error)
        store.dispatch({ type: actionType.LOADING_SPIN, payload: { loading: false } })
        if (error?.response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_401) {
            Cookies.remove("access_token");
            Cookies.remove("userId");
            Cookies.remove("roles");
            return <Navigate to="/login" />;
        }
        if (error?.response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_404) {
            // Router.push(APP_ROUTES.PAGE_404);
        }
        return Promise.reject(error?.response?.data ?? { message: MESSAGES.MSG_E_001 });
    }
);

const checkUserSaveLog = (response) => {
    let configAPI = response.config

    try {
        if (REQUEST_SAVE_LOG.includes(configAPI.method)) {
            if (configAPI.rawData !== undefined) {
                let rawData = JSON.parse(JSON.stringify(configAPI.rawData))
                if (rawData.isSaveLog) { //For API POST/PUT/PATCH
                    let params = {
                        urlApi: configAPI.url,
                        methodType: configAPI.method,
                        content: JSON.stringify(rawData)
                    }
                    api.post("/api/log/create-log", { ...params }).catch((err) => {
                        console.log(err?.message ?? err?.messages ?? "Save log failed")
                    })
                }
            } else if (configAPI.isSaveLog) { //FOR API DELETE
                let params = {
                    urlApi: configAPI.url,
                    methodType: configAPI.method,
                    content: JSON.stringify({ action: "Delete DATA" })
                }
                api.post("/api/log/create-log", { ...params }).catch((err) => {
                    console.log(err?.message ?? err?.messages ?? "Save log failed")
                })
            }
        }
    } catch (err) {
        console.log("SAVE LOG ERROR: ", err.message)
    }
}

export { api };
