import {
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import MESSAGES from "config/constant/messages";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import AntdButton from "stories/Button/AntdButton";
import AntdInput from "stories/Input/AntdInput";
import AntdUploadButton from "stories/UploadButton/AntdUploadButton";
import AntdTable from "stories/Table/AntdTable";
import { Link } from "react-router-dom";
import { uploadServices } from "services/UploadService";
import { processServices } from "services/ProcessService";
import HTTP_STATUS_CONTSTANTS from "config/constant/httpStatus";
import { JAPAN_TIMEZONE } from "config/constant/timezone";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ACTIVE_EXCEL_BUTTON_BY_STATUS = ["EXCEL作成済", "EXCEL更新済"];

const ListDataPage = ({
  onFinish,
  onFinishFailed,
  form,
  data,
  listStatus,
  listDocsType,
  onChange,
  pagination,
}) => {
  const { downloadExcelFile } = processServices;
  const { uploadExcel } = uploadServices;

  const handleNoUpload = async () => {
    return;
  };

  const handleChange = (info, record) => {
    try {
      let formData = new FormData();
      // handle upload single file
      formData.append("file", info.file.originFileObj);
      uploadExcel({
        transactionTypeId: record.transactionTypeId,
        transactionCode: record.transactionCode,
        formData: formData,
      })
        .then((res) => {
          if (res.code === HTTP_STATUS_CONTSTANTS.SUCCESS) {
            message.success(
              res?.message ??
                res?.messages ??
                res?.data?.message ??
                res?.data?.messages ??
                "Upload Success"
            );
          } else {
            message.error(
              res?.message ??
                res?.messages ??
                res?.data?.message ??
                res?.data?.messages ??
                "Upload Failed"
            );
          }
        })
        .catch((err) => {
          err?.message ??
            err?.messages ??
            err?.data?.message ??
            err?.data?.messages ??
            "Upload Failed";
        });
    } catch (error) {
      return "Error uploading file: " + error.message;
    }
  };

  const uploadedColumns = [
    {
      title: "PDFファイル名称",
      dataIndex: "filenamePDF",
      key: "filenamePDF",
    },
    {
      title: "ドキュメント種類",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "アップロード日付",
      dataIndex: "timeUpload",
      key: "timeUpload",
      render: (date) => {
        const originalDate = moment(date);
        const formattedDate = originalDate.format("YYYY-MM-DD HH:mm:ss");
        const japanTime = dayjs(formattedDate)
          .tz(JAPAN_TIMEZONE)
          .format("YYYY/MM/DD");
        return <p>{japanTime}</p>;
      },
    },
    {
      title: "取引コード",
      dataIndex: "transactionCode",
      key: "transactionCode",
      render: (transactionCode) => {
        return (
          <Link
            to={`/detail-data/${transactionCode}`}
            className="underline underline-offset-2 text-blue-400"
          >
            {transactionCode}
          </Link>
        );
      },
    },
    {
      title: "ステータス",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      width: 400,
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex justify-center items-center">
            <AntdButton
              label={"EXCELダウンロード"}
              disabled={
                !ACTIVE_EXCEL_BUTTON_BY_STATUS.includes(record.statusName)
              }
              className="text-white button-white"
              onClick={() => downloadExcelFileAPI(record)}
            />
            <Divider type="vertical" />
            <AntdUploadButton
              className={"text-white"}
              multiple={false}
              disabled={
                !ACTIVE_EXCEL_BUTTON_BY_STATUS.includes(record.statusName)
              }
              accept={".xlsx"}
              customRequest={handleNoUpload}
              onChange={(file) => handleChange(file, record)}
              // fileList={uploadList}
              label={"EXCELアップロード"}
              showUploadList={false}
            />
          </div>
        );
      },
    },
  ];

  const downloadExcelFileAPI = async (record) => {
    try {
      let responseData = await downloadExcelFile({
        transactionTypeId: record.transactionTypeId,
        transactionCode: record.transactionCode,
      });
      const href = URL.createObjectURL(responseData);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${record.transactionCode}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error while downloading Excel file:", error.message);
    }
  };

  const disabledEndDate = (current) => {
    if (form.getFieldValue("fromDate")) {
      let startTimeText = form.getFieldValue("fromDate").format("YYYY-MM-DD");
      const start = moment(startTimeText, "YYYY-MM-DD");
      return current < start;
    } else return undefined;
  };

  const disabledStartDate = (current) => {
    if (form.getFieldValue("toDate")) {
      let endTimeText = form.getFieldValue("toDate").format("YYYY-MM-DD");
      const end = moment(endTimeText, "YYYY-MM-DD");
      return current > end;
    } else return undefined;
  };

  return (
    <div className="container mx-auto">
      <div className="mt-10 mb-5 flex space-y-4 flex-col items-left justify-left">
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Row>
            <Col className="flex" span={8}>
              <Col span={16}>
                <Form.Item
                  className="form-label-text-left"
                  label="アップロード日付"
                  name="fromDate"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  colon={false}
                >
                  <DatePicker
                    disabledDate={disabledStartDate}
                    className="w-full"
                    placeholder="開始日付"
                    format={"YYYY/MM/DD"}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="toDate"
                  className="pl-1"
                  wrapperCol={{ span: 24 }}
                >
                  <DatePicker
                    disabledDate={disabledEndDate}
                    className="w-full"
                    placeholder="終了日付"
                    format={"YYYY/MM/DD"}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col span={8}>
              <Form.Item
                className="form-label-text-left label-pl-2"
                label="ステータス"
                name="status"
                colon={false}
              >
                <Select options={listStatus} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                className="form-label-text-left"
                label="ドキュメント種類"
                name="type"
                colon={false}
              >
                <Select options={listDocsType} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="form-label-text-left label-pl-2"
                label="取引コード"
                name="transactionCode"
                colon={false}
              >
                <AntdInput allowClear maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={8} className="text-right">
              <AntdButton
                type="primary"
                htmlType="submit"
                label={"検索"}
                className="w-40"
                autoInsertSpaceInButton={false}
              />
            </Col>
          </Row>
        </Form>
      </div>

      <ConfigProvider
        renderEmpty={() => <div className="">{MESSAGES.MSG_I_015}</div>}
      >
        <AntdTable
          columns={uploadedColumns}
          dataSource={data}
          bordered={true}
          onChange={onChange}
          pagination={pagination}
        />
      </ConfigProvider>
    </div>
  );
};

export default ListDataPage;
