import { apiUpload } from "config/apiUpload";
import { api } from "config/api";

class UploadServices {
  getListPDFUpload = async (params) => {
    Object.keys(params).forEach((key) => {
      params[key] === undefined && delete params[key];
    });
    const queryString = new URLSearchParams(params).toString();
    return api.get(`/api/transaction-process/list-pdf?${queryString}`);
  };
  uploadToBlob = async (params) => {
    const { containerName, formData, referenceCode } = params;
    return apiUpload.post(
      `/api/blob-storage/upload/${containerName}/${referenceCode}`,
      formData
    );
  };
  uploadExcel = async (params) => {
    const { transactionTypeId, transactionCode, formData } = params;
    return apiUpload.post(
      `/api/storage/excel/${transactionCode}/${transactionTypeId}`,
      formData
    );
  };
  uploadPDF = async (params) => {
    const { transactionCode, transactionTypeId, formData } = params;
    return apiUpload.post(
      `/api/storage/pdf/${transactionCode}/${transactionTypeId}`,
      formData
    );
  };
}

export const uploadServices = new UploadServices();
