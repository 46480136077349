import { Button, Form, Input, message, Modal, notification, Radio } from "antd";
import AntdButton from "stories/Button/AntdButton";
import { useEffect } from "react";
import { userServices } from "services/UserServices";
import MESSAGES from "config/constant/messages";
import { REGEX_PASSWORD } from "config/constant/regex";

const CreateUserModal = ({
  isModalOpen,
  onCloseModal,
  editingRecord,
  setEditingRecord,
  getListUserAPI,
  pagination,
  isEdit,
}) => {
  const { createUser, updateUser } = userServices;
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(editingRecord).length > 0) {
      form.setFieldsValue({
        ...editingRecord,
        role: editingRecord.roles.name ?? undefined,
      });
    }
  }, [editingRecord]);

  const onFinish = (values) => {
    if (Object.keys(editingRecord).length > 0) {
      updateUser({ ...values, id: editingRecord.id })
        .then(() => {
          message.success(MESSAGES.MSG_I_014(editingRecord.userId));
          getListUserAPI(pagination);
          handleCancel();
        })
        .catch((err) =>
          message.error(
            err?.message
              ? err?.message
              : `${editingRecord.userId}の編集に失敗しました。`
          )
        );
    } else {
      createUser({ ...values })
        .then(() => {
          message.success(MESSAGES.MSG_I_013(values.userId));
          getListUserAPI(pagination);
          handleCancel();
        })
        .catch((err) => {
          message.error(
            err?.message
              ? err?.message
              : `${editingRecord.userId}の追加に失敗しました。`
          );
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    form.resetFields();
    setEditingRecord({});
    onCloseModal();
  };

  return (
    <Modal
      title={isEdit ? "ユーザー編集" : "ユーザー追加"}
      open={isModalOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      footer={[
        <Button
          className="button-white"
          key="cancel"
          onClick={handleCancel}
          type="primary"
        >
          キャンセル
        </Button>,
        <AntdButton
          label="保存"
          className="button-white"
          key="link"
          htmlType="submit"
          type={"primary"}
          // loading={loading}
          onClick={() => form.submit()}
          autoInsertSpaceInButton={false}
        />,
      ]}
    >
      <div className="change-password-container">
        <div className="change-password-form-container">
          {Object.keys(editingRecord).length == 0 ? (
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="ユーザーID"
                name="userId"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.MSG_E_003("ユーザーID"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="パスワード"
                name="password"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        //Value blank
                        return Promise.reject(
                          new Error(MESSAGES.MSG_E_003("パスワード"))
                        );
                      }
                      if (value.length >= 5 && value.length <= 16) {
                        if (REGEX_PASSWORD.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(MESSAGES.MSG_E_005));
                        }
                      }
                      return Promise.reject(
                        new Error(MESSAGES.MSG_E_004(5, 16))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="パスワード再確認"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        //Value blank
                        return Promise.reject(
                          new Error(MESSAGES.MSG_E_003("パスワード再確認"))
                        );
                      } else if (getFieldValue("password") != value) {
                        return Promise.reject(new Error(MESSAGES.MSG_E_006));
                      } else if (getFieldValue("password") == value) {
                        //New pass not same with old pass
                        if (value.length >= 5 && value.length <= 16) {
                          if (REGEX_PASSWORD.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(MESSAGES.MSG_E_005)
                            );
                          }
                        }
                        return Promise.reject(
                          new Error(MESSAGES.MSG_E_004(5, 16))
                        );
                      } else return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="アカウント種別"
                name="role"
                className="radio-flex-row"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: "アカウント種別を選択してください。",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"ROLE_ADMIN"}>管理者</Radio>
                  <Radio value={"ROLE_USER"}>一般</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          ) : (
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item label="ユーザーID" name="userId">
                <Input />
              </Form.Item>
              <Form.Item label="パスワード" name="password">
                <Input.Password disabled />
              </Form.Item>

              <Form.Item label="パスワード再確認" name="confirmPassword">
                <Input.Password disabled />
              </Form.Item>
              <Form.Item
                label="アカウント種別"
                name="role"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className="radio-flex-row"
                rules={[
                  {
                    required: true,
                    message: "アカウント種別を選択してください。",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"ROLE_ADMIN"}>管理者</Radio>
                  <Radio value={"ROLE_USER"}>一般</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
